import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-spinner-panel',
  standalone: true,
  imports: [TranslateModule],
  template: `
    <div class="spinner" role="status">
      <div
        style="background-color:white; height:100px; width:200px; text-align: center; vertical-align: middle; padding: 8px; border-radius: 10px;"
      >
        <span class="spinner-border text-secondary"></span>
        <b>
          <h4 class="text-color mt-1">{{ 'xCargando' | translate }}...</h4>
          <h4 class="text-color">{{ 'xAguarde' | translate }}</h4>
        </b>
      </div>
    </div>
  `,
  styles: [
    `
      .text-color {
        color: #35302f !important;
        font-weight: 600;
      }
    `,
  ],
})
export class SpinnerPanelComponent {}
